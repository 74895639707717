import React, { memo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { ButtonVariant } from 'components/atoms/button'
import { useRouter } from 'next/router'
import TypeformLogo from 'components/atoms/common/logo'
import { Palette } from '@typeform/ginger/dist/constants/palettes'

import { routes } from '../../utils'
import { useLocaleContext } from '../locale-context'

import {
  Action,
  Actions,
  ActionTitle,
  Container,
  Header,
  Headline,
  Main,
  OuterContainer,
  PrimaryCTA,
  SecondaryCTA,
} from './private-typeform.styles'
import { UTMContentValues } from './constants'

const PrivateTypeform = () => {
  const { t } = useTranslation()
  const { language } = useLocaleContext()
  const { query } = useRouter()
  const primaryLink = UTMContentValues.includes(query.utm_content)
    ? routes(language).home
    : routes().explore

  return (
    <OuterContainer>
      <Container data-testid='private-typeform-container'>
        <Header>
          <TypeformLogo
            href={t('public-header.logo.href')}
            title={t('public-header.logo.title')}
            palette={Palette.Negative}
          />
        </Header>
        <Main>
          <Headline>{t('public-site.private-url.headline')}</Headline>
          <Actions>
            <Action>
              <ActionTitle>
                {t('public-site.private-url.action-title-1')}
              </ActionTitle>
              <SecondaryCTA
                href={routes(language).login}
                variant={ButtonVariant.Secondary}
                palette={Palette.Negative}
              >
                {t('public-site.private-url.action-link-1')}
              </SecondaryCTA>
            </Action>
            <Action>
              <ActionTitle>
                {t('public-site.private-url.action-title-2')}
              </ActionTitle>
              <PrimaryCTA
                href={`${primaryLink}?source=private`}
                palette={Palette.Negative}
              >
                {t('public-site.private-url.action-link-2')}
              </PrimaryCTA>
            </Action>
          </Actions>
        </Main>
      </Container>
    </OuterContainer>
  )
}

export default memo(PrivateTypeform)
