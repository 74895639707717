import {
  X_DEFAULT_LOCALE,
  LANGUAGE_CODES,
} from '@typeform/ginger/dist/lib/i18n'
import React from 'react'
import PropType from 'prop-types'
import PrivateTypeform from 'components/private-typeform'
import useTranslation from 'next-translate/useTranslation'
import DocumentHead from 'components/head'
import { routes } from 'utils/routes'
import { getCommonServerSideProps } from 'utils/server'
import { toCanonicalURL } from 'utils/url-analyzers'
import { logError } from 'server/utils/logger'

const PrivateTypeformPage = ({ currentUrl, hrefLangLinks }) => {
  const { t } = useTranslation()

  return (
    <>
      <DocumentHead
        title={t('private-typeform.meta-tag.title')}
        description={t('private-typeform.meta-tag.description')}
        currentUrl={currentUrl}
        hrefLangLinks={hrefLangLinks}
      />
      <PrivateTypeform />
    </>
  )
}

PrivateTypeformPage.defaultProps = {
  hrefLangLinks: [],
}

PrivateTypeformPage.displayName = 'PrivateTypeformPage'

PrivateTypeformPage.propTypes = {
  currentUrl: PropType.string.isRequired,
  hrefLangLinks: PropType.arrayOf(
    PropType.shape({
      href: PropType.string,
      hreflang: PropType.string,
    })
  ),
}

export const getServerSideProps = async ctx => {
  try {
    const baseUrl = toCanonicalURL()
    const hrefLangLinks = [
      {
        hreflang: LANGUAGE_CODES.english,
        href: routes(LANGUAGE_CODES.english, baseUrl).privateTypeform,
      },
      {
        hreflang: LANGUAGE_CODES.spanish,
        href: routes(LANGUAGE_CODES.spanish, baseUrl).privateTypeform,
      },
      {
        hreflang: X_DEFAULT_LOCALE,
        href: routes(LANGUAGE_CODES.english, baseUrl).privateTypeform,
      },
    ]
    const commonServerSideProps = await getCommonServerSideProps(ctx)

    return {
      props: {
        ...commonServerSideProps,
        appConfig: {
          headerProps: {
            showHeader: false,
          },
          footerProps: {
            showFooter: false,
          },
        },
        hrefLangLinks,
      },
    }
  } catch (error) {
    logError({
      message: 'Error in private-typeform getServerSideProps',
      error,
      additionalInfo: { section: 'private-typeform' },
    })
    return {
      notFound: true,
    }
  }
}

export default PrivateTypeformPage
