import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  fontSize,
  fontWeight,
} from '@typeform/ginger/dist/constants/typography'
import styled from 'styled-components'
import Button, { ButtonElement } from 'components/atoms/button'
import {
  NeutralColors,
  UIPalette,
} from '@typeform/ginger/dist/constants/colors'

export const OuterContainer = styled.div`
  background-color: ${NeutralColors.Ink} !important;
  color: white !important;
`

export const Container = styled.div`
  min-height: 100vh;
  max-width: 1200px;
  padding: 32px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr;

  ${mediaqueries.md`
    padding: 32px 80px;
  `}
`

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Header = styled.header`
  margin-bottom: 56px;

  ${mediaqueries.md`
    margin-bottom: 0;
  `}
`

export const Headline = styled.h1`
  font-size: ${fontSize.x2Large};
  line-height: 44px;
  margin-bottom: 64px;
  max-width: 800px;

  ${mediaqueries.md`
    font-size: 50px;
    line-height: 128%;
  `}
`

export const Actions = styled.div`
  max-width: 223px;

  ${mediaqueries.md`
    max-width: unset;
    width: 100%;
  `}
`

export const Action = styled.div`
  &:not(:last-child) {
    margin-bottom: 48px;
  }
  ${mediaqueries.md`
    display: inline-grid;
    justify-items: center;
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 80px;
    }
  `}
`

export const ActionTitle = styled.p`
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.medium};
  line-height: 28px;
  text-align: center;
`

export const PrimaryCTA = styled(Button)`
  ${ButtonElement} {
    background-color: ${UIPalette.Yellow400};
    border-color: ${UIPalette.Yellow400};
    min-width: 225px;
  }
`
export const SecondaryCTA = styled(Button)`
  ${ButtonElement} {
    min-width: 225px;
  }
`
